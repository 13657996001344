import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import app from '../main';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            auth: 'none',
        },
    },
    {
        path: '/sparqlConfigEditor',
        name: 'sparqlConfigEditor',
        component: () => import('../views/SparqlConfigEditorView.vue'),
        meta: {
            auth: 'none', // admin
        },
    },
    {
        path: '/sparqlDataEditor',
        name: 'sparqlDataEditor',
        component: () => import('../views/SparqlDataEditorView.vue'),
        meta: {
            auth: 'none', // admin
        },
    },
    {
        path: '/formEditor',
        name: 'formEditor',
        component: () => import('../views/FormEditorView.vue'),
        meta: {
            auth: 'none', // admin
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, from) => {
    app.config.globalProperties.routerLoad = true;
    if (to.meta.auth != 'none') {
        let keycloak;
        const basePath = new URL(window.location.toString());

        while (keycloak == null) {
            await sleep(100);
            keycloak = app.config.globalProperties.keycloak;
        }

        if (!keycloak.authenticated) {
            keycloak.login({
                redirectUri:
                    basePath.protocol + '//' + basePath.host + '/#' + to.path,
            });
        } else if (keycloak.hasRealmRole(to.meta.auth)) {
            keycloak
                .updateToken(70)
                .then(() => {
                    app.config.globalProperties.routerLoad = false;
                    return true;
                })
                .catch(() => {
                    app.config.globalProperties.routerLoad = false;
                    if (from.name) {
                        return { name: from.name };
                    } else {
                        return { name: 'home' };
                    }
                });
        } else {
            app.config.globalProperties.routerLoad = false;
            if (from.name) {
                return { name: from.name };
            } else {
                return { name: 'home' };
            }
        }
    } else {
        app.config.globalProperties.routerLoad = false;
        return true;
    }
});

export default router;
