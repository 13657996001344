import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Keycloak from 'keycloak-js';
import axios from 'axios';

const app = createApp(App);

axios
    .get('http://localhost:8080/keycloak')
    .then(function (response) {
        const keycloak = new Keycloak({
            url: response.data.url,
            realm: response.data.realm,
            clientId: response.data.clientId,
        });
        keycloak
            .init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/silent-check-sso.html',
            })
            .then(function () {
                app.config.globalProperties.keycloak = keycloak;
            })
            .catch(function () {
                alert('Failed to initialize keycloak');
            });
    })
    .catch(function () {
        alert('Failed to initialize keycloak');
    });

app.use(router).mount('#app');

export default app;
